export type ArticleOpenedData = {
  article_id: string;
  last_opened_at: string;
};

export type ArticleBookmarkModifiedData = {
  article_id: string | number;
  bookmarked: boolean;
  bookmark_modified_at: string;
};

export type EditionOpenedData = {
  edition_id: string | number;
  last_opened_at: string;
};

export type PageVisitedData = {
  edition_id: string | number;
  last_visited_page_number: number;
  last_visited_page_at: string;
};

export type EditionBookmarkModifiedData = {
  edition_id: string | number;
  bookmarked: boolean;
  bookmark_modified_at: string;
};

export enum AppEvent {
  ArticleOpened = "article_opened",
  ArticleBookmarkModified = "article_bookmark_modified",
  EditionOpened = "edition_opened",
  PageVisited = "page_visited",
  EditionBookmarkModified = "edition_bookmark_modified",
}

export type AppEventData =
  | { type: AppEvent.ArticleOpened; data: ArticleOpenedData }
  | {
      type: AppEvent.ArticleBookmarkModified;
      data: ArticleBookmarkModifiedData;
    }
  | { type: AppEvent.EditionOpened; data: EditionOpenedData }
  | { type: AppEvent.PageVisited; data: PageVisitedData }
  | {
      type: AppEvent.EditionBookmarkModified;
      data: EditionBookmarkModifiedData;
    };
