import { formatDistance, format } from "date-fns";
import { nl } from "date-fns/locale";
import { computed, Ref } from "vue";
import { Article } from "../types/article";

export const useArticleTimeAgo = (article: Ref<Article | undefined>) => {
  return computed(() => {
    if (!article.value?.edition?.published_at) return null;
    const createdAt = new Date(article.value.edition?.published_at);
    const now = new Date();
    const oneMonthInMs = 30 * 24 * 60 * 60 * 1000;

    if (now.getTime() - createdAt.getTime() > oneMonthInMs) {
      return format(createdAt, "dd MMM yyyy", { locale: nl });
    }
    return formatDistance(createdAt, now, {
      addSuffix: true,
      locale: nl,
    });
  });
};
