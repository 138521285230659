import { createApp } from "vue";
import i18n from "./lib/i18n";
import { createPinia } from "pinia";
import { createHead } from "@unhead/vue";
import * as Sentry from "@sentry/vue";
import VueGtag from "vue-gtag-next";
import router from "./routes";
import { VueQueryPlugin, VueQueryPluginOptions } from "@tanstack/vue-query";
import App from "./App.vue";

import "./assets/css/style.css";

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
};

const pinia = createPinia();
const head = createHead();

const app = createApp(App);

if (!import.meta.env.DEV) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: ["localhost", import.meta.env.VITE_API_HOST],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app
  .use(i18n)
  .use(pinia)
  .use(head)
  .use(VueQueryPlugin, vueQueryPluginOptions)
  .use(router)
  .use(VueGtag, {
    property: {
      id: import.meta.env.VITE_GOOGLE_MEASUREMENT_ID,
    },
  })
  .mount("#app");
