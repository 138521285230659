import { onMounted, onUnmounted, Ref } from "vue";

export const useInfinityScroll = ({
  scrollComponent,
  fetchNextPage,
  scrollContainer,
}: {
  scrollComponent: Ref<HTMLElement | undefined>;
  scrollContainer?: string;
  fetchNextPage: () => void;
}) => {
  const handleScroll = () => {
    let element = scrollComponent.value;
    if (
      element &&
      element.getBoundingClientRect().bottom <= window.innerHeight
    ) {
      fetchNextPage();
    }
  };

  onMounted(() => {
    const container = scrollContainer
      ? document.querySelector(scrollContainer)
      : window;
    container?.addEventListener("scroll", handleScroll);
  });

  onUnmounted(() => {
    const container = scrollContainer
      ? document.querySelector(scrollContainer)
      : window;
    container?.removeEventListener("scroll", handleScroll);
  });
};
