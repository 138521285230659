<script setup lang="ts">
import Modal from "../Modal.vue";
import LoginModal from "../LoginModal.vue";
import LogoutModal from "../LogoutModal.vue";
import EditionModal from "../EditionModal.vue";
import EditionDownloadModal from "../EditionDownloadModal.vue";
import appConfig from "../../lib/appConfig";
import { useHead } from "@unhead/vue";
import { useAuthStore } from "../../stores/authStore";
import { useContentStore } from "../../stores/contentStore";
import { onBeforeRouteLeave } from "vue-router";

const authStore = useAuthStore();
const contentStore = useContentStore();

useHead({
  titleTemplate: `%s - ${appConfig.strings.app_name}`,
});

onBeforeRouteLeave(() => {
  contentStore.showEditionModal = false;
  contentStore.showDownloadModal = false;
});

// authStore.init();
</script>

<template>
  <div>
    <slot></slot>
    <Modal v-if="authStore.user" v-model="authStore.showLogoutModal">
      <LogoutModal @close="authStore.showLogoutModal = false" />
    </Modal>
    <Modal v-else v-model="authStore.showLoginModal" size="lg">
      <LoginModal @close="authStore.closeLoginModal" />
    </Modal>
    <Modal v-model="contentStore.showEditionModal" size="lg">
      <EditionModal @close="contentStore.showEditionModal = false" />
    </Modal>
    <Modal v-model="contentStore.showDownloadModal">
      <EditionDownloadModal @close="contentStore.showDownloadModal = false" />
    </Modal>
  </div>
</template>
