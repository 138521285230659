<script setup lang="ts">
import { useArticleTimeAgo } from "../lib/article";
import { Article } from "../types/article";
import { Clock } from "./icons";
import { computed, toRef } from "vue";

const props = withDefaults(
  defineProps<{
    article: Article;
    vertical?: boolean;
    imageWidth?: string;
  }>(),
  {
    vertical: false,
    imageWidth: "w-28",
  }
);

const timeAgo = useArticleTimeAgo(toRef(props, "article"));

const readTime = computed(() => {
  return Math.round(props.article.reading_seconds / 60) + " min";
});
</script>

<template>
  <RouterLink
    class="pb-2 border-b border-border-primary"
    :class="{ 'flex gap-2': vertical }"
    :to="`/article/${article.id}`"
  >
    <img
      class="bg-background-tertiary object-cover"
      :class="vertical ? imageWidth : 'w-full'"
      :src="article.thumbnail_url"
      style="aspect-ratio: 3/2"
      width="910"
      height="640"
    />
    <div class="w-full flex flex-col gap-2" :class="{ 'pt-3': !vertical }">
      <h3 class="font-bold leading-5 line-clamp-2 text-ellipsis h-10">
        {{ article.name }}
      </h3>
      <div
        class="flex justify-between items-center text-text-tertiary text-sm gap-2 leading-4 mt-auto"
      >
        <div class="flex items-center shrink-0">
          <p class="mr-1">{{ readTime }}</p>
          <Clock size="18" class="text-icon-secondary" />
        </div>
        <p class="text-right text-nowrap">{{ timeAgo }}</p>
      </div>
    </div>
  </RouterLink>
</template>
