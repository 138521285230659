<script setup lang="ts">
import Sidebar from "./Sidebar.vue";
import MobileNav from "./MobileNav.vue";
import BaseLayout from "./BaseLayout.vue";
import Modal from "../Modal.vue";
import SearchModal from "../searchModal/SearchModal.vue";
import { useContentStore } from "../../stores/contentStore";
import { useMediaQuery } from "@vueuse/core";

const isLargeScreen = useMediaQuery("(min-width: 768px)");
const contentStore = useContentStore();
</script>

<template>
  <BaseLayout>
    <div class="md:pl-72">
      <Sidebar v-if="isLargeScreen" />
      <MobileNav v-else />
      <main>
        <router-view />
      </main>
    </div>
    <Modal v-model="contentStore.showSearchModal" size="lg">
      <SearchModal @close="contentStore.showSearchModal = false" />
    </Modal>
  </BaseLayout>
</template>
