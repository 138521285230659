<script setup lang="ts">
import { ref } from "vue";
import LoadingButton from "./general/LoadingButton.vue";
import { postEditionDownload } from "../repository";
import { useContentStore } from "../stores/contentStore";
import { notify } from "@kyvg/vue3-notification";
import { useI18n } from "vue-i18n";
import { AnalyticsEvent, useAnalytics } from "../lib/analytics";
import logger from "../lib/logger";

const emit = defineEmits(["close"]);

const { t } = useI18n();
const { logEvent } = useAnalytics();
const contentStore = useContentStore();

const isLoading = ref(false);

const downloadPdf = (src: string) => {
  const link = document.createElement("a");
  link.href = src;
  link.download = "";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleDownload = async () => {
  if (!contentStore.downloadSelectedEdition) return;
  isLoading.value = true;
  try {
    const res = await postEditionDownload(
      contentStore.downloadSelectedEdition.id
    );
    if (!res.data.data.pdf_url) {
      throw new Error("pdf_url not found");
    }
    downloadPdf(res.data.data.pdf_url);
    notify({
      type: "success",
      title: t("edition_download_modal.success_message.title"),
    });
    logEvent(AnalyticsEvent.ContentDownload, {
      contentId: contentStore.downloadSelectedEdition.id,
      contentType: "edition",
      contentName: contentStore.downloadSelectedEdition.name,
    });
    emit("close");
  } catch (err) {
    logger.error((err as Error).message, {
      editionId: contentStore.downloadSelectedEdition.id,
    });
    notify({
      type: "error",
      title: t("edition_download_modal.error_message.title"),
      text: t("edition_download_modal.error_message.text"),
    });
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <div class="p-4 w-full">
    <h2 class="text-xl font-bold mb-2">
      {{ $t("edition_download_modal.title") }}
    </h2>
    <p class="mb-6 text-text-secondary">
      {{ $t("edition_download_modal.description") }}
    </p>
    <div class="grid grid-cols-2 gap-3">
      <button @click="$emit('close')" class="btn-secondary">
        {{ $t("common.cancel") }}
      </button>
      <LoadingButton @click="handleDownload" :loading="isLoading">
        {{ $t("edition_download_modal.download_button") }}
      </LoadingButton>
    </div>
  </div>
</template>
