import { useGtag } from "vue-gtag-next";

export enum AnalyticsEvent {
  Search = "search",
  ContentPreview = "content_preview",
  ContentBookmark = "content_bookmark",
  ContentDownload = "content_download",
  Share = "share",
  EditionPageOpen = "edition_page_open",
  Login = "login",
}

export const useAnalytics = () => {
  const { event } = useGtag();

  function logEvent(
    eventName: AnalyticsEvent.Search,
    data: {
      query: string;
    }
  ): void;
  function logEvent(
    eventName:
      | AnalyticsEvent.Share
      | AnalyticsEvent.ContentPreview
      | AnalyticsEvent.ContentBookmark
      | AnalyticsEvent.ContentDownload,
    data: {
      contentId: number;
      contentType: "edition" | "article";
      contentName?: string;
      method?: string;
      bookmarked?: boolean;
    }
  ): void;
  function logEvent(
    eventName: AnalyticsEvent.EditionPageOpen,
    data: {
      editionId: number;
      pageNumber: number;
    }
  ): void;
  function logEvent(eventName: AnalyticsEvent.Login, data: null): void;
  function logEvent(eventName: AnalyticsEvent, data: any): void {
    event(eventName, data);
  }

  return { logEvent };
};
