import { createRouter, createWebHistory } from "vue-router";
import Layout from "./components/layout/Layout.vue";
import { useAuthStore } from "./stores/authStore";
import { trackRouter } from "vue-gtag-next";

const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      { path: "/", component: () => import("./pages/Home.vue") },
      { path: "/articles", component: () => import("./pages/Articles.vue") },
      { path: "/magazines", component: () => import("./pages/Magazines.vue") },
      {
        path: "/saved",
        meta: { requiresAuth: true },
        component: () => import("./pages/Saved.vue"),
      },
      {
        path: "/account",
        meta: { requiresAuth: true },
        component: () => import("./pages/Account.vue"),
      },
    ],
  },
  {
    path: "/article/:id",
    component: () => import("./pages/Article.vue"),
  },
  {
    path: "/edition/:id",
    meta: { requiresAuth: true },
    component: () => import("./pages/Edition.vue"),
  },
  {
    path: "/auth/logout",
    component: () => import("./pages/AuthLogout.vue"),
  },
  {
    path: "/deeplink",
    component: () => import("./pages/DeepLink.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("./pages/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  const authStore = useAuthStore();

  if (to.meta.requiresAuth) {
    if (!(await authStore.checkAuth())) {
      next({ path: "/" });
      return;
    }
  }
  next();
});

trackRouter(router, {
  useScreenview: true,
});

export default router;
