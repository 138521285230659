import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const sessionId = uuidv4();
const isDev = import.meta.env.DEV;

export const getLocalInstallationId = () => {
  const id = localStorage.getItem("installationId");
  if (id) {
    return id;
  } else {
    const newId = uuidv4();
    localStorage.setItem("installationId", newId);
    return newId;
  }
};

export const getSessionId = () => sessionId;

const sendLog = async (severity: string, message: string, context: any) => {
  const payload = {
    appSessionUuid: sessionId,
    appInstallationUuid: getLocalInstallationId(),
    severity,
    message,
    context: {
      href: window.location.href,
      context,
    },
  };

  try {
    await axios.post("/api/log", payload);
  } catch (error) {
    console.error("Failed to log", error);
  }
};

export const debug = (message: string, context?: any) => {
  if (isDev) {
    console.debug(message, context);
  }
};

export const info = (message: string, context?: any) => {
  if (isDev) {
    console.info(message, context);
  }
};

export const warn = (message: string, context?: any) => {
  if (isDev) {
    console.warn(message, context);
  } else {
    sendLog("WARNING", message, context);
  }
};

export const error = (message: string, context?: any, error?: Error) => {
  if (isDev) {
    console.error(message, error, context);
  } else {
    sendLog("ERROR", message, context);
  }
};

export default {
  debug,
  info,
  warn,
  error,
};
