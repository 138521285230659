<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { useField } from "vee-validate";

const props = withDefaults(
  defineProps<{
    class?: string;
    label: string;
    name: string;
    type?: string;
    disabled?: boolean;
  }>(),
  {
    type: "text",
  }
);

const { value, errorMessage } = useField<string>(props.name);
</script>

<template>
  <div :class="class">
    <div
      class="relative rounded-lg text-text-primary"
      :class="disabled ? 'text-opacity-40' : null"
    >
      <label
        class="absolute top-2 left-4 text-xs pointer-events-none"
        :for="name"
        >{{ label }}</label
      >
      <input
        class="bg-background-secondary pb-2 pt-7 px-4 w-full rounded-lg"
        :type="type"
        :name="name"
        :disabled="disabled"
        v-model="value"
        v-bind="$attrs"
      />
    </div>
    <transition>
      <div v-if="errorMessage" class="text-error text-sm mt-1">
        {{ errorMessage }}
      </div>
    </transition>
  </div>
</template>
