import type {
  Config,
  ContentConfig,
  StringsConfig,
  LinksConfig,
  ColorsConfig,
  ContactFaq,
  SubscriptionPage,
} from "../types/config";

class AppConfig implements Config {
  public variant: string;
  public app_version: string;
  public thumbnail_aspect_ratio: number;
  public allow_downloads: boolean;
  public content: ContentConfig;
  public strings: StringsConfig;
  public links: LinksConfig;
  public colors: ColorsConfig;
  public titleId: number;
  public subscriptionPage: SubscriptionPage;
  public contactFaqs?: ContactFaq[];

  constructor() {
    this.variant = __VARIANT__;
    this.app_version = __APP_VERSION__;
    const config = __VARIANT_CONFIG__;

    this.thumbnail_aspect_ratio = config.thumbnail_aspect_ratio;
    this.allow_downloads = config.allow_downloads;

    this.titleId = config.content.title_id;

    this.content = config.content;
    this.strings = config.strings;
    this.links = config.links;
    this.colors = config.colors;
    this.subscriptionPage = config.subscription_page;
    this.contactFaqs = config.contact_faqs;
  }

  public get images() {
    return {
      icon_app: `/icon_app.svg`,
      logo_color: `/logo_color.svg`,
      logo_full_color: `/logo_full_color.svg`,
      logo_full_white: `/logo_full_white.svg`,
      logo_white: `/logo_white.svg`,
      covers: `/covers.png`,
    };
  }

  public get auth() {
    return {
      type: import.meta.env.VITE_AUTH_TYPE,
      host: import.meta.env.VITE_AUTH_HOST,
      authorizePath: import.meta.env.VITE_AUTH_AUTHORIZE_PATH,
      logoutPath: import.meta.env.VITE_AUTH_LOGOUT_PATH,
      clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
      callbackUrlLogout: import.meta.env.VITE_AUTH_CALLBACK_URL_LOGOUT,
    };
  }

  public get api() {
    return {
      scheme: import.meta.env.VITE_API_SCHEME,
      host: import.meta.env.VITE_API_HOST,
    };
  }

  public getCssVars() {
    return Object.entries(this.colors).map(([key, value]) => {
      const keyName = key.replace(/_/g, "-");
      return `--color-${keyName}: ${this.hexToRgb(value)};`;
    });
  }

  private hexToRgb(hex: string): string {
    hex = hex.replace(/^#/, "");
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return `${r} ${g} ${b}`;
  }
}

const appConfig = new AppConfig();

export default appConfig;
