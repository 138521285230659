<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { useField } from "vee-validate";

const props = withDefaults(
  defineProps<{
    class?: string;
    name: string;
    rows?: number;
  }>(),
  {
    rows: 5,
  }
);

const { value, errorMessage } = useField<string>(props.name);
</script>

<template>
  <div>
    <textarea
      class="bg-background-secondary p-4 w-full rounded-lg align-bottom"
      :name="name"
      :rows="rows"
      v-model="value"
      v-bind="$attrs"
    />
    <transition>
      <div v-if="errorMessage" class="text-error text-sm mt-1">
        {{ errorMessage }}
      </div>
    </transition>
  </div>
</template>
