<script setup lang="ts">
import { toRef } from "vue";
import { Edition } from "../types/edition";
import CircleProgress from "./general/CircleProgress.vue";
import { Check } from "./icons";
import { useContentStore } from "../stores/contentStore.ts";
import { useEditionProgress } from "../lib/edition.ts";
import appConfig from "../lib/appConfig.ts";

const contentStore = useContentStore();

const props = defineProps<{
  edition: Edition;
}>();

const { hasProgress, progress } = useEditionProgress(toRef(props, "edition"));

const handleClick = () => {
  contentStore.selectedEdition = props.edition;
};
</script>

<template>
  <button @click="handleClick" class="text-left">
    <img
      class="shadow-lg mb-2 bg-background-tertiary w-full object-cover"
      :class="{
        'grayscale opacity-60': !edition.end_user_subscription?.has_access,
      }"
      :style="{
        aspectRatio: appConfig.thumbnail_aspect_ratio,
      }"
      :src="edition.thumbnail_url"
      :alt="edition.name"
    />
    <p class="mb-1">{{ edition.name }}</p>
    <div
      v-if="!hasProgress"
      class="text-xs px-2 py-0.5 rounded-full border border-border-secondary inline-block"
    >
      {{ $t("magazine.card.unread") }}
    </div>
    <div v-else-if="progress === 100" class="flex items-center">
      <div
        class="h-5 w-5 bg-background-brand rounded-full flex-center text-icon-white mr-1"
      >
        <Check weight="bold" size="12" />
      </div>
      <span class="text-text-primary text-sm font-medium">{{
        $t("magazine.card.done")
      }}</span>
    </div>
    <div v-else class="flex items-center">
      <CircleProgress :value="progress" class="mr-1" />
      <span class="text-text-tertiary text-sm font-medium">
        {{ progress }}%
      </span>
    </div>
  </button>
</template>
