<script setup lang="ts">
import { onMounted, ref, watch } from "vue";

const props = defineProps<{
  options: { label: string; value: string }[];
}>();

const model = defineModel();

const listRefs = ref<any[]>([]);
const highlightX = ref(0);
const highlightWidth = ref(0);
const initialized = ref(false);

const setHighlight = () => {
  const index = props.options.findIndex(
    (option) => option.value === model.value
  );
  const el = listRefs.value[index];
  if (el) {
    const { width, x } = el.getBoundingClientRect();
    const { x: firstX } = listRefs.value[0].getBoundingClientRect();
    highlightWidth.value = width;
    highlightX.value = x - firstX;
  }
  setTimeout(() => {
    initialized.value = true;
  }, 150);
};

watch(model, setHighlight);
onMounted(setHighlight);
</script>

<template>
  <ul class="flex relative border-b border-border-primary">
    <li v-for="option in options">
      <button
        class="px-4 py-2 relative z-1 h-10 transition-colors"
        :class="
          model === option.value ? 'text-text-primary' : 'text-text-tertiary'
        "
        :ref="(el) => listRefs.push(el)"
        @click="model = option.value"
      >
        {{ option.label }}
      </button>
      <div
        class="absolute -bottom-px h-[2px] bg-border-brand"
        :class="{ 'transition-all': initialized }"
        :style="{ width: highlightWidth + 'px', left: highlightX + 'px' }"
      />
    </li>
  </ul>
</template>
