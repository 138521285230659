<script setup lang="ts">
import { XMark } from "../icons";
import { useQuery } from "@tanstack/vue-query";
import { getFaqs } from "../../repository";
import FaqQuestion from "./FaqQuestion.vue";

const emit = defineEmits(["close"]);

const { isLoading, isError, data, refetch } = useQuery({
  queryKey: ["faq"],
  queryFn: () => getFaqs(),
});
</script>

<template>
  <div
    class="h-[38rem] max-h-screen flex flex-col rounded-xl overflow-hidden w-full"
  >
    <div class="flex items-center justify-between p-4 md:p-6">
      <h2 class="font-black text-xl">{{ $t("help_menu.faq") }}</h2>
      <button @click="$emit('close')" class="btn-round-secondary">
        <XMark />
      </button>
    </div>
    <div class="overflow-auto flex-1 px-4 md:px-6 pb-4 md:pb-6">
      <div v-if="isLoading || isError" class="flex-center min-h-40">
        <LoadingIndicator v-if="isLoading" class="text-icon-brand" />
        <ErrorState v-else-if="isError" @refetch="refetch" />
      </div>
      <div v-if="data" class="grid gap-2">
        <FaqQuestion v-for="faq in data?.data.data" :key="faq.id" :faq="faq" />
      </div>
    </div>
  </div>
</template>
