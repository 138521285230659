<script setup lang="ts">
import { XMark, Download, Bookmark, Share, Lock } from "./icons";
import { useContentStore } from "../stores/contentStore";
import { useAuthStore } from "../stores/authStore.ts";
import { storeToRefs } from "pinia";
import ProgressBar from "./ProgressBar.vue";
import { useEditionProgress } from "../lib/edition.ts";
import appConfig from "../lib/appConfig.ts";
import EditionArticles from "./editions/EditionArticles.vue";
import LoadingIndicator from "./general/LoadingIndicator.vue";
import { computed, onMounted, ref } from "vue";
import { pushAppEvents } from "../repository/index.ts";
import { AppEvent } from "../types/appEvents.ts";
import { notify } from "@kyvg/vue3-notification";
import Modal from "./Modal.vue";
import ShareModal from "./ShareModal.vue";
import { useQueryClient } from "@tanstack/vue-query";
import { useI18n } from "vue-i18n";
import { AnalyticsEvent, useAnalytics } from "../lib/analytics.ts";

const emit = defineEmits(["close"]);

const loadingBookmark = ref(false);
const localBookmark = ref<boolean>();
const showShareModal = ref(false);

const { t } = useI18n();
const { logEvent } = useAnalytics();
const queryClient = useQueryClient();

const authStore = useAuthStore();
const contentStore = useContentStore();
const { selectedEdition: edition } = storeToRefs(contentStore);

const { hasProgress, progress } = useEditionProgress(edition);

const isBookmarked = computed(() => {
  if (localBookmark.value !== undefined) return localBookmark.value;
  if (!edition.value?.end_user_edition_data) return false;
  return edition.value.end_user_edition_data?.bookmarked ?? false;
});

const hasAccess = computed(() => {
  return edition.value?.end_user_subscription?.has_access;
});

const resetEditionQueries = () => {
  queryClient.invalidateQueries({
    queryKey: ["recent-editions"],
  });
  queryClient.invalidateQueries({
    queryKey: ["continue-reading-editions"],
  });
  queryClient.invalidateQueries({
    queryKey: ["saved", "magazines"],
  });
};

const toggleBookmark = async () => {
  if (!edition.value || loadingBookmark.value) return;
  loadingBookmark.value = true;
  try {
    await pushAppEvents([
      {
        type: AppEvent.EditionBookmarkModified,
        data: {
          edition_id: edition.value.id,
          bookmarked: !isBookmarked.value,
          bookmark_modified_at: new Date().toISOString(),
        },
      },
    ]);
    localBookmark.value = !isBookmarked.value;
    resetEditionQueries();
    logEvent(AnalyticsEvent.ContentBookmark, {
      contentId: edition.value.id,
      contentType: "edition",
      contentName: edition.value.name,
    });
  } catch (err) {
    notify({
      type: "error",
      title: t("common.error.title"),
      text: t("common.error.message"),
    });
  } finally {
    loadingBookmark.value = false;
  }
};

const downloadPdf = () => {
  contentStore.downloadSelectedEdition = contentStore.selectedEdition;
};

onMounted(() => {
  if (edition.value) {
    logEvent(AnalyticsEvent.ContentPreview, {
      contentId: edition.value.id,
      contentType: "edition",
      contentName: edition.value.name,
    });
  }
});

const handleSignInClick = () => {
  contentStore.showEditionModal = false;
  authStore.showLoginModal = true;
};
</script>

<template>
  <div v-if="edition" class="overflow-hidden max-h-[38rem] w-full rounded-xl">
    <button
      class="btn-round-white absolute top-6 right-6 z-20"
      @click="$emit('close')"
    >
      <XMark />
    </button>
    <div class="relative overflow-auto h-full" id="edition-modal">
      <div
        class="bg-background-secondary absolute h-40 w-full top-0 left-0 -z-10"
      />
      <div class="px-6 pt-6 flex">
        <img
          class="shadow-lg mb-2 bg-background-tertiary object-cover w-32"
          :class="{
            'grayscale opacity-60': !edition.end_user_subscription?.has_access,
          }"
          :style="{
            aspectRatio: appConfig.thumbnail_aspect_ratio,
          }"
          :src="edition.thumbnail_url"
          :alt="edition.name"
        />
        <div class="flex-1 ml-6 pt-14">
          <h2 class="text-xl font-bold mb-2">{{ edition.name }}</h2>
          <ProgressBar v-if="hasProgress" :progress="progress" />
        </div>
      </div>
      <div class="px-6 pb-6 mt-4">
        <div v-if="hasAccess">
          <RouterLink class="btn-primary w-full" :to="`/edition/${edition.id}`">
            {{
              hasProgress
                ? $t("edition_modal.continue_reading_button")
                : $t("edition_modal.open_button")
            }}
          </RouterLink>
          <div class="flex flex-col md:flex-row gap-3 mt-3">
            <button
              v-if="appConfig.allow_downloads"
              class="btn-white flex-1"
              :class="{ 'btn--border': hasAccess }"
              :disabled="!hasAccess"
              @click="downloadPdf"
            >
              <Download size="20" class="mr-1" />
              <span>{{ $t("edition_modal.download_button") }}</span>
            </button>
            <button
              class="btn-white flex-1"
              :class="{ 'btn--border': hasAccess }"
              :disabled="!hasAccess"
              @click="toggleBookmark"
            >
              <LoadingIndicator v-if="loadingBookmark" size="sm" />
              <Bookmark
                v-else
                size="20"
                class="mr-1"
                :weight="isBookmarked ? 'fill' : 'regular'"
              />
              <span>{{
                isBookmarked
                  ? $t("edition_modal.bookmark_remove")
                  : $t("edition_modal.bookmark_add")
              }}</span>
            </button>
            <button
              class="btn-white flex-1"
              :class="{ 'btn--border': hasAccess }"
              :disabled="!hasAccess"
              @click="showShareModal = true"
            >
              <Share size="20" class="mr-1" />
              <span>{{ $t("edition_modal.share_button") }}</span>
            </button>
          </div>
        </div>
        <div
          v-else
          class="bg-background-secondary p-3 gap-2 flex items-center rounded-xl"
        >
          <div class="mb-auto">
            <Lock weight="fill" size="24" class="text-icon-primary" />
          </div>
          <div>
            <h4 class="text-text-primary font-bold">
              {{ $t("edition_modal.no_access.title") }}
            </h4>
            <p class="text-text-secondary">
              {{ $t("edition_modal.no_access.description") }}
            </p>
          </div>
          <button
            @click="handleSignInClick"
            class="btn-primary"
            v-if="!authStore.user"
          >
            {{ $t("common.sign_in") }}
          </button>
          <a
            v-else
            class="btn-primary"
            :href="appConfig.links.subscription_url"
          >
            {{ $t("common.subscribe") }}
          </a>
        </div>
        <div class="mt-4">
          <h3 class="text-lg font-semibold pb-2 border-b border-border-primary">
            {{ $t("edition_modal.articles_title") }}
          </h3>
          <EditionArticles class="mt-4" :edition="edition" />
        </div>
      </div>
    </div>
    <Modal v-model="showShareModal">
      <ShareModal
        @click="showShareModal = false"
        type="edition"
        :edition="edition"
      />
    </Modal>
  </div>
</template>
