<script setup lang="ts">
import { Facebook, XTwitter, Youtube, Instagram } from "../icons";
import { useAppSettingsStore } from "../../stores/appSettingsStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const store = useAppSettingsStore();
const { settings } = storeToRefs(store);

const hasSocials = computed(() => {
  return (
    settings.value?.url_facebook ||
    settings.value?.url_instagram ||
    settings.value?.url_twitter ||
    settings.value?.url_youtube
  );
});
</script>

<template>
  <div class="mt-4" v-if="hasSocials">
    <p class="mb-2">{{ $t("help_menu.follow_us") }}</p>
    <div class="flex items-center gap-2">
      <a
        v-if="settings?.url_facebook"
        :href="settings.url_facebook"
        target="_blank"
        class="help-social-icon"
      >
        <Facebook />
      </a>
      <a
        v-if="settings?.url_instagram"
        :href="settings.url_instagram"
        target="_blank"
        class="help-social-icon"
      >
        <Instagram />
      </a>
      <a
        v-if="settings?.url_twitter"
        :href="settings.url_twitter"
        target="_blank"
        class="help-social-icon"
      >
        <XTwitter />
      </a>
      <a
        v-if="settings?.url_youtube"
        :href="settings.url_youtube"
        target="_blank"
        class="help-social-icon"
      >
        <Youtube />
      </a>
    </div>
  </div>
</template>
