import { defineStore } from "pinia";
import {
  getCurrentUser,
  refreshMySubscriptions,
  UserInclude,
} from "../repository";
import { computed, ref, watch } from "vue";
import { EndUser } from "../types/user";

export const useAuthStore = defineStore("auth", () => {
  const loginModalClosePressed = localStorage.getItem("loginModalClosePressed");
  const showLoginModal = ref(loginModalClosePressed !== "true");
  const showLogoutModal = ref(false);
  const user = ref<EndUser>();
  const isLoading = ref(false);

  const fetchUser = async () => {
    isLoading.value = true;
    try {
      const res = await getCurrentUser({
        include: [
          UserInclude.Subscriptions,
          UserInclude.EndUserPropertyValues,
          UserInclude.EndUserPropertyValuesEndUserProperty,
        ],
      });
      user.value = res.data.data;
    } catch (err) {
      // logic called in axios interceptor
    } finally {
      isLoading.value = false;
    }
  };

  const checkAuth = async () => {
    if (!isLoading.value) {
      return !!user.value;
    }

    const waitForLoading = new Promise((resolve) => {
      watch(isLoading, (value) => {
        if (!value) {
          resolve(!!user.value);
        }
      });
    });

    return await waitForLoading;
  };

  const refreshSubscriptions = async () => {
    const res = await refreshMySubscriptions();
    if (user.value) {
      user.value.subscriptions = res.data;
    }
  };

  const closeLoginModal = () => {
    showLoginModal.value = false;
    localStorage.setItem("loginModalClosePressed", "true");
  };

  const hasActiveSubscription = computed(() => {
    return user.value?.subscriptions?.data?.length ?? 0 > 0;
  });

  if (!user.value) {
    fetchUser();
  }

  return {
    user,
    isLoading,
    hasActiveSubscription,
    showLoginModal,
    showLogoutModal,
    checkAuth,
    refreshSubscriptions,
    closeLoginModal,
  };
});
