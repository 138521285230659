<script setup lang="ts">
import { Search, XMark } from "../icons";
import TabControl from "../general/TabControl.vue";
import { computed, onMounted, ref, watch } from "vue";
import { debounce } from "../../lib/utils";
import AllTab from "./AllTab.vue";
import EditionsTab from "./EditionsTab.vue";
import ArticlesTab from "./ArticlesTab.vue";
import { useI18n } from "vue-i18n";
import { AnalyticsEvent, useAnalytics } from "../../lib/analytics";

defineEmits(["close"]);

const { t } = useI18n();
const { logEvent } = useAnalytics();

const type = ref<"all" | "magazines" | "articles">("all");
const searchInput = ref<HTMLInputElement>();
const inputQuery = ref("");
const searchQuery = ref("");

const options = [
  { label: t("search.label.all"), value: "all" },
  { label: t("search.label.magazines"), value: "magazines" },
  { label: t("search.label.articles"), value: "articles" },
];

watch(inputQuery, (newValue) => {
  debounceInput(newValue);
});

const debounceInput = debounce((query: string) => {
  searchQuery.value = query;
  logEvent(AnalyticsEvent.Search, { query });
}, 500);

const emptyDescription = computed(() => {
  if (type.value === "articles") {
    return t("search.start_articles");
  } else if (type.value === "magazines") {
    return t("search.start_magazines");
  } else {
    return t("search.start_all");
  }
});

onMounted(() => {
  searchInput.value?.focus();
});
</script>

<template>
  <div class="rounded-xl overflow-hidden flex flex-col w-full">
    <div>
      <div class="flex items-center p-4">
        <div class="relative mr-4 flex-1">
          <Search class="absolute left-4 top-3 text-icon-secondary" />
          <input
            class="py-3 pl-12 pr-4 rounded-xl bg-background-secondary border border-border-primary w-full h-12"
            type="text"
            :placeholder="$t('search.placeholder')"
            v-model="inputQuery"
            ref="searchInput"
          />
        </div>
        <button class="btn-round-secondary" @click="$emit('close')">
          <XMark />
        </button>
      </div>
      <TabControl v-model="type" :options="options" />
    </div>
    <div v-if="searchQuery.length < 1" class="min-h-40 flex-center">
      <div class="text-center">
        <p class="font-bold">{{ $t("search.start_title") }}</p>
        <p class="text-text-secondary">{{ emptyDescription }}</p>
      </div>
    </div>
    <div v-else class="overflow-auto" id="search-modal-container">
      <AllTab
        v-if="type === 'all'"
        :searchQuery="searchQuery"
        @openTab="(t) => (type = t)"
      />
      <EditionsTab
        v-else-if="type === 'magazines'"
        :searchQuery="searchQuery"
      />
      <ArticlesTab v-else-if="type === 'articles'" :searchQuery="searchQuery" />
    </div>
  </div>
</template>
