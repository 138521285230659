<script setup lang="ts">
import { EmblaOptionsType } from "embla-carousel";
import emblaCarouselVue from "embla-carousel-vue";
import EditionCard from "../EditionCard.vue";
import { Edition } from "../../types/edition";
import { onMounted } from "vue";

const props = defineProps<{
  emblaOptions?: EmblaOptionsType;
  editions: Edition[];
}>();

const emit = defineEmits(["init"]);

const [emblaRef, emblaApi] = emblaCarouselVue({
  dragFree: true,
  ...props.emblaOptions,
});

onMounted(() => {
  emit("init", emblaApi.value);
});
</script>

<template>
  <div class="overflow-hidden" ref="emblaRef">
    <div class="flex">
      <div
        v-for="edition in editions"
        :key="edition.id"
        class="shrink-0 min-w-0 mr-5"
      >
        <EditionCard :edition="edition" class="w-32 md:w-44" />
      </div>
    </div>
  </div>
</template>
