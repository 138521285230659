<script setup lang="ts">
import { useField } from "vee-validate";

const props = defineProps<{
  name: string;
}>();

const { value } = useField<string>(props.name);
</script>

<template>
  <div class="flex items-center">
    <input type="checkbox" :id="name" :name="name" v-model="value" />
    <label :for="name" class="ml-2">
      <slot />
    </label>
  </div>
</template>
