import client from "./client";
import appConfig from "../lib/appConfig";
import { Edition } from "../types/edition";
import { Article } from "../types/article";
import { EndUser, Subscriptions } from "../types/user";
import { AppSettings } from "../types/appSettings";
import { Faq } from "../types/faq";
import { AppEventData } from "../types/appEvents";
import { AppLink } from "../types/appLink";

const v1Path = "/v1/app";
const v2Path = "/v2/app";

export interface Pagination<T> {
  data: T[];
  meta: {
    next_cursor: string | null;
  };
}

export enum EditionInclude {
  EndUserEditionData = "end_user_edition_data",
  EndUserSubscription = "end_user_subscription",
  Pages = "pages",
  Spreads = "spreads,spreads.first_page,spreads.second_page",
}

export function getTitleEditions({
  titleId,
  cursor,
  include,
}: {
  titleId: number;
  cursor: string;
  include?: EditionInclude[];
}) {
  return client.get<Pagination<Edition>>(
    `${v2Path}/titles/${titleId}/editions`,
    {
      params: {
        cursor: cursor,
        per_page: 16,
        sort_key: "published_at",
        sort_dir: "desc",
        include: include && include.join(","),
      },
    }
  );
}

export function getEdition(
  id: string | number,
  { include }: { include?: EditionInclude[] } = {}
) {
  return client.get<{ data: Edition }>(`${v1Path}/editions/${id}`, {
    params: {
      include: include && include.join(","),
    },
  });
}

export enum ArticleInclude {
  Edition = "edition",
  FirstPage = "first_page",
  EndUserArticleData = "end_user_article_data",
  EndUserSubscription = "end_user_subscription",
}

export function getTitleArticles({
  titleId,
  cursor,
  sortKey,
  include,
}: {
  titleId: number;
  cursor: string;
  sortKey?: string;
  include?: ArticleInclude[];
}) {
  return client.get<Pagination<Article>>(
    `${v2Path}/titles/${titleId}/articles`,
    {
      params: {
        cursor: cursor,
        per_page: 16,
        sort_key: sortKey ?? "published_at",
        sort_dir: "desc",
        include: include && include.join(","),
      },
    }
  );
}

export function getArticle(
  id: string | number,
  {
    include,
  }: {
    include?: ArticleInclude[];
  } = {}
) {
  return client.get<{ data: Article }>(`${v1Path}/articles/${id}`, {
    params: {
      include: include && include.join(","),
    },
  });
}

export function getArticleRelatedArticles(
  articleId: string | number,
  {
    cursor,
    include,
  }: {
    cursor: string;
    include?: ArticleInclude[];
  }
) {
  return client.get<Pagination<Article>>(
    `${v2Path}/articles/${articleId}/related-articles`,
    {
      params: {
        cursor: cursor,
        per_page: 2,
        sort_key: "match_score",
        sort_dir: "desc",
        include: include && include.join(","),
      },
    }
  );
}

export function getEditionArticles({
  editionId,
  cursor,
  include,
}: {
  editionId: number;
  cursor: string;
  include?: ArticleInclude[];
}) {
  return client.get<Pagination<Article>>(
    `${v2Path}/editions/${editionId}/articles`,
    {
      params: {
        cursor: cursor,
        per_page: 16,
        sort_key: "popularity",
        sort_dir: "desc",
        include: include && include.join(","),
      },
    }
  );
}

export function getEditionArticlesAll(
  editionId: string | number,
  { include }: { include?: ArticleInclude[] } = {}
) {
  return client.get<{ data: Article[] }>(
    `${v1Path}/editions/${editionId}/articles/all`,
    {
      params: {
        sort_key: "first_page.number",
        sort_dir: "asc",
        include: include && include.join(","),
      },
    }
  );
}

export function postArticleShareLink(id: string | number) {
  return client.post<{ data: { url: string } }>(
    `${v1Path}/articles/${id}/share-link`
  );
}

export function postEditionShareLink(id: string | number) {
  return client.post<{ data: { url: string } }>(
    `${v1Path}/editions/${id}/share-link`
  );
}

export function getAppSettings() {
  return client.get<{ data: AppSettings }>(`${v1Path}/app-settings`);
}

export enum UserInclude {
  Subscriptions = "subscriptions",
  EndUserPropertyValues = "end_user_property_values",
  EndUserPropertyValuesEndUserProperty = "end_user_property_values.end_user_property",
}

export function getCurrentUser({ include }: { include?: UserInclude[] } = {}) {
  return client.get<{ data: EndUser }>(`${v1Path}/me`, {
    params: {
      include: include && include.join(","),
    },
  });
}

export function getTitleEditionsCurrentlyReading({
  titleId,
  cursor,
  include,
}: {
  titleId: number;
  cursor: string;
  include?: EditionInclude[];
}) {
  return client.get<Pagination<Edition>>(
    `${v2Path}/titles/${titleId}/editions/currently-reading`,
    {
      params: {
        cursor: cursor,
        per_page: 16,
        sort_key: "last_opened_at",
        sort_dir: "desc",
        include: include && include.join(","),
      },
    }
  );
}

export function getTitleEditionsBookmarked({
  titleId,
  cursor,
  include,
}: {
  titleId: number;
  cursor: string;
  include?: EditionInclude[];
}) {
  return client.get<Pagination<Edition>>(
    `${v1Path}/titles/${titleId}/editions/bookmarked`,
    {
      params: {
        cursor: cursor,
        per_page: 16,
        sort_key: "bookmark_modified_at",
        sort_dir: "desc",
        include: include && include.join(","),
      },
    }
  );
}

export function getTitleEditionsSearch({
  query,
  titleId,
  cursor,
  include,
}: {
  query: string;
  titleId: number;
  cursor: string;
  include?: EditionInclude[];
}) {
  return client.get<Pagination<Edition>>(
    `${v2Path}/titles/${titleId}/editions/search`,
    {
      params: {
        query: query,
        cursor: cursor,
        per_page: 8,
        sort_key: "match_score",
        sort_dir: "desc",
        include: include && include.join(","),
      },
    }
  );
}

export function getTitleArticlesBookmarked({
  titleId,
  cursor,
  include,
}: {
  titleId: number;
  cursor: string;
  include?: ArticleInclude[];
}) {
  return client.get<Pagination<Article>>(
    `${v2Path}/titles/${titleId}/articles/bookmarked`,
    {
      params: {
        cursor: cursor,
        per_page: 16,
        sort_key: "bookmark_modified_at",
        sort_dir: "desc",
        include: include && include.join(","),
      },
    }
  );
}

export function getTitleArticlesSearch({
  query,
  titleId,
  cursor,
  include,
}: {
  query: string;
  titleId: number;
  cursor: string;
  include?: ArticleInclude[];
}) {
  return client.get<Pagination<Article>>(
    `${v2Path}/titles/${titleId}/articles/search`,
    {
      params: {
        query: query,
        cursor: cursor,
        per_page: 8,
        sort_key: "match_score",
        sort_dir: "desc",
        include: include && include.join(","),
      },
    }
  );
}

export function getFaqs() {
  return client.get<{ data: Faq[] }>(`${v1Path}/faqs`);
}

export type ContactBody = {
  name: string;
  email: string;
  message: string;
  appSessionUuid?: string;
  appInstallationUuid?: string;
};

export function sendContactForm({
  name,
  email,
  message,
  appSessionUuid,
  appInstallationUuid,
}: ContactBody) {
  return client.post(`${v1Path}/contact-forms`, {
    name,
    email,
    message,
    build_number: appConfig.app_version,
    user_agent: navigator.userAgent,
    app_session_uuid: appSessionUuid,
    app_installation_uuid: appInstallationUuid,
  });
}

export function updateUser({
  firstName,
  lastName,
  include,
}: {
  firstName: string;
  lastName: string;
  include?: UserInclude[];
}) {
  return client.put<{ data: EndUser }>(
    `${v1Path}/profile`,
    {
      firstname: firstName,
      lastname: lastName,
    },
    {
      params: {
        include: include && include.join(","),
      },
    }
  );
}

export function pushAppEvents(events: AppEventData[]) {
  return client.post(
    `${v1Path}/actions/push-app-events-idempotent`,
    events.map((e) => ({
      type: e.type,
      data: e.data,
    }))
  );
}

export function postEditionDownload(id: number | string) {
  return client.post<{ data: { pdf_url?: string } }>(
    `${v1Path}/editions/${id}/download`,
    {
      os_id: "web",
    },
    {
      params: {
        download_type: "pdf",
      },
    }
  );
}

export function getAppLink(id: number | string) {
  return client.get<{ data: AppLink }>(`${v1Path}/app-links/${id}`, {
    params: {
      include: "linkable,linkable.linkable",
    },
  });
}

export function refreshMySubscriptions() {
  return client.post<Subscriptions>(
    `${v1Path}/actions/refresh-my-subscriptions`
  );
}
