<script setup lang="ts">
import { ref } from "vue";
import { RouterLink } from "vue-router";
import appConfig from "../../lib/appConfig";
import { Magazine, Bookmark, MobilePhone, Article, Home } from "../icons";
import HelpButton from "../help/HelpButton.vue";
import SidebarCta from "../layout/SidebarCta.vue";
import Modal from "../Modal.vue";
import AppDownloadModal from "../AppDownloadModal.vue";
import { useAuthStore } from "../../stores/authStore";
import Socials from "../help/Socials.vue";

const authStore = useAuthStore();

const showDownloadModal = ref(false);
</script>

<template>
  <aside
    class="fixed top-0 left-0 h-screen bg-background-primary w-72 px-4 pb-4 pt-10 flex flex-col justify-between border-r border-border-primary"
  >
    <div>
      <RouterLink to="/">
        <img
          class="w-36"
          :src="appConfig.images.logo_color"
          :alt="appConfig.strings.app_name"
        />
      </RouterLink>
      <ul class="flex flex-col gap-2 mt-10">
        <li>
          <RouterLink to="/" class="sidebar-item">
            <Home size="28" />
            <span>{{ $t("menu.home") }}</span>
          </RouterLink>
        </li>
        <li>
          <RouterLink to="/magazines" class="sidebar-item">
            <Magazine size="28" weight="fill" />
            <span>{{ $t("menu.magazines") }}</span>
          </RouterLink>
        </li>
        <li>
          <RouterLink to="/articles" class="sidebar-item">
            <Article size="28" weight="fill" />
            <span>{{ $t("menu.articles") }}</span>
          </RouterLink>
        </li>
        <li v-if="authStore.user">
          <RouterLink to="/saved" class="sidebar-item">
            <Bookmark size="28" weight="fill" />
            <span>{{ $t("menu.saved") }}</span>
          </RouterLink>
        </li>
      </ul>
    </div>
    <div class="mt-10">
      <SidebarCta class="mb-2" v-if="!authStore.hasActiveSubscription" />
      <button
        class="sidebar-item-secondary mb-2"
        @click="showDownloadModal = true"
      >
        <MobilePhone size="28" /><span>{{ $t("menu.download_app") }}</span>
      </button>
      <HelpButton />
      <Socials />
    </div>
  </aside>
  <Modal v-model="showDownloadModal">
    <AppDownloadModal @close="showDownloadModal = false" />
  </Modal>
</template>
