import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { Edition } from "../types/edition";

export const useContentStore = defineStore("content", () => {
  const selectedEdition = ref<Edition | undefined>();
  const downloadSelectedEdition = ref<Edition | undefined>();
  const showSearchModal = ref(false);

  const showEditionModal = computed({
    get: () => !!selectedEdition.value,
    set: (value: boolean) => {
      if (!value) {
        selectedEdition.value = undefined;
      }
    },
  });

  const showDownloadModal = computed({
    get: () => !!downloadSelectedEdition.value,
    set: (value: boolean) => {
      if (!value) {
        downloadSelectedEdition.value = undefined;
      }
    },
  });

  return {
    selectedEdition,
    downloadSelectedEdition,
    showSearchModal,
    showEditionModal,
    showDownloadModal,
  };
});
