<script setup lang="ts">
import { onMounted, onUnmounted, watch, computed } from "vue";
const props = withDefaults(defineProps<{ size?: "md" | "lg" }>(), {
  size: "md",
});

const model = defineModel({ type: Boolean, required: true });
const emit = defineEmits(["close"]);

const onClose = () => {
  model.value = false;
  emit("close");
};

const handleKeyDown = (event: any) => {
  if (event.key === "Escape") {
    onClose();
  }
};

onMounted(() => {
  if (model.value) {
    document.addEventListener("keydown", handleKeyDown);
  }
});

watch(model, (open) => {
  if (open) {
    document.addEventListener("keydown", handleKeyDown);
  } else {
    document.removeEventListener("keydown", handleKeyDown);
  }
});

onUnmounted(() => {
  document.removeEventListener("keydown", handleKeyDown);
});

const sizeClass = computed(() => {
  return props.size === "md" ? "modal-container--md" : "modal-container--lg";
});
</script>

<template>
  <Teleport to="#main">
    <transition>
      <div
        v-if="model"
        class="fixed inset-0 bg-background-overlay/40 z-40"
        @click="onClose"
      >
        <div class="modal-container flex" :class="sizeClass" @click.stop>
          <slot></slot>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
