import { notify } from "@kyvg/vue3-notification";
import { i18n } from "../lib/i18n";
import axios, { AxiosError } from "axios";

const client = axios.create({
  baseURL: "/api/app?url=",
  timeout: 30000,
  withCredentials: true,
});

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

const interceptor = client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      client.interceptors.response.eject(interceptor);

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            return client(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/auth/refresh")
          .then(() => {
            processQueue(null);
            resolve(client(originalRequest));
          })
          .catch((err) => {
            if ((err as AxiosError).status === 401) {
              notify({
                type: "error",
                title: i18n.t("auth.refresh_error.title"),
                text: i18n.t("auth.refresh_error.text"),
              });
            }
            processQueue(err);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default client;
