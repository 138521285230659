import { defineStore } from "pinia";
import { ref } from "vue";
import { getAppSettings } from "../repository";
import { AppSettings } from "../types/appSettings";
import logger from "../lib/logger";

export const useAppSettingsStore = defineStore("app-settings", () => {
  const settings = ref<AppSettings>();
  const isLoading = ref(true);
  const isError = ref(false);

  const fetchSettings = async () => {
    isLoading.value = true;
    try {
      const res = await getAppSettings();
      settings.value = res.data.data;
    } catch (err) {
      logger.error("failed to fetch app settings", {
        error: (err as Error).message,
      });
      isError.value = true;
    } finally {
      isLoading.value = false;
    }
  };

  if (!settings.value) {
    fetchSettings();
  }

  return {
    settings: settings,
    isLoading: isLoading,
    isError: isError,
  };
});
