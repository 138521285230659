<script setup lang="ts">
import { useInfiniteQuery } from "@tanstack/vue-query";
import { ArticleInclude, getEditionArticles } from "../../repository/index.ts";
import { Edition } from "../../types/edition.ts";
import ArticleCard from "../ArticleCard.vue";
import { computed, ref } from "vue";
import { useInfinityScroll } from "../../lib/infinityScroll.ts";
import LoadingIndicator from "../general/LoadingIndicator.vue";
import { ErrorState, EmptyState } from "../states/index.ts";

const scrollComponent = ref<HTMLElement>();

const props = defineProps<{
  edition: Edition;
}>();

const { isLoading, isError, data, fetchNextPage, isFetchingNextPage, refetch } =
  useInfiniteQuery({
    queryKey: ["edition-articles", props.edition.id],
    queryFn: ({ pageParam }) =>
      getEditionArticles({
        editionId: props.edition.id,
        cursor: pageParam,
        include: [
          ArticleInclude.Edition,
          ArticleInclude.EndUserArticleData,
          ArticleInclude.EndUserSubscription,
        ],
      }),
    initialPageParam: "null",
    getNextPageParam: (lastPage) => {
      return lastPage.data.meta.next_cursor;
    },
  });

const articles = computed(() => {
  return data.value?.pages.flatMap((page) => page.data.data);
});

const isEmpty = computed(() => articles.value?.length === 0);

useInfinityScroll({
  scrollComponent,
  fetchNextPage,
  scrollContainer: "#edition-modal",
});
</script>

<template>
  <div ref="scrollComponent">
    <div v-if="isLoading || isError || isEmpty" class="flex-center min-h-40">
      <LoadingIndicator v-if="isLoading" class="text-icon-brand" />
      <ErrorState
        v-else-if="isError"
        :contentName="$t('common.articles')"
        @refetch="refetch"
      />
      <EmptyState
        v-else-if="isEmpty"
        :title="$t('edition_modal.no_articles')"
      />
    </div>
    <div v-else-if="articles">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <ArticleCard
          v-for="article in articles"
          :key="article.id"
          :article="article"
        />
      </div>
      <div v-if="isFetchingNextPage" class="w-full flex-center py-4">
        <LoadingIndicator class="text-icon-brand" />
      </div>
    </div>
  </div>
</template>
