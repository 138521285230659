<script setup lang="ts">
import { ref } from "vue";
import { Chat, InfoCircle, QuestionCirlce, WhatsApp, Website } from "../icons";
import { useAppSettingsStore } from "../../stores/appSettingsStore";
import { storeToRefs } from "pinia";
import Modal from "../Modal.vue";
import FaqModal from "./FaqModal.vue";
import ContactFormModal from "./ContactFormModal.vue";
import appConfig from "../../lib/appConfig.ts";

const store = useAppSettingsStore();
const { settings } = storeToRefs(store);

const open = ref(false);
const openFaqModal = ref(false);
const openContactFormModal = ref(false);
</script>

<template>
  <div @mouseover="open = true" @mouseleave="open = false" class="relative">
    <transition>
      <div v-if="open" class="absolute bottom-full left-0 pb-2 w-full">
        <div
          class="bg-background-primary rounded-xl border border-border-primary shadow-card px-4"
        >
          <ul>
            <li class="help-item">
              <button @click="openFaqModal = true">
                <QuestionCirlce />
                <span>{{ $t("help_menu.faq") }}</span>
              </button>
            </li>
            <li class="help-item">
              <button @click="openContactFormModal = true">
                <Chat />
                <span>{{ $t("help_menu.contact_form") }}</span>
              </button>
            </li>
            <li v-if="settings?.contact_whatsapp" class="help-item">
              <a
                :href="`https://wa.me/${settings.contact_whatsapp.replace(
                  /[^0-9]/g,
                  ''
                )}`"
              >
                <WhatsApp />
                <span>WhatsApp</span>
              </a>
            </li>
            <li class="help-item">
              <a :href="appConfig.links.website_url" target="_blank">
                <Website />
                <span>Website</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </transition>
    <button
      class="sidebar-item-secondary"
      @click="open = !open"
      @touchstart.prevent="open = !open"
    >
      <InfoCircle size="28" /><span>{{ $t("menu.help") }}</span>
    </button>
    <Modal v-model="openFaqModal" size="lg">
      <FaqModal @close="openFaqModal = false" />
    </Modal>
    <Modal v-model="openContactFormModal" size="lg">
      <ContactFormModal @close="openContactFormModal = false" />
    </Modal>
  </div>
</template>
