<script setup lang="ts">
import appConfig from "../../lib/appConfig";
import {
  Magazine,
  Bookmark,
  MobilePhone,
  Article,
  Home,
  XMark,
} from "../icons";
import { useAuthStore } from "../../stores/authStore";
import HelpButton from "../help/HelpButton.vue";
import Modal from "../Modal.vue";
import AppDownloadModal from "../AppDownloadModal.vue";
import { ref } from "vue";
import Socials from "../help/Socials.vue";

const model = defineModel();

const showDownloadModal = ref(false);

const authStore = useAuthStore();

const handleClose = () => {
  model.value = false;
};
</script>

<template>
  <transition>
    <div
      v-if="model"
      class="fixed inset-0 z-40 bg-background-overlay/40"
      @click="handleClose"
    />
  </transition>
  <transition name="slide-fade">
    <div v-if="model" class="fixed left-0 top-0 z-40 w-full h-full flex">
      <aside
        class="bg-background-primary p-4 overflow-auto h-full w-full max-w-80 flex flex-col"
        @click.prevent
      >
        <RouterLink to="/" @click="handleClose">
          <img
            class="w-24"
            :src="appConfig.images.logo_color"
            :alt="appConfig.strings.app_name"
          />
        </RouterLink>
        <nav>
          <div class="h-px bg-border-primary my-4"></div>
          <ul class="flex flex-col gap-2" @click="handleClose">
            <li>
              <RouterLink to="/" class="sidebar-item">
                <Home size="28" />
                <span>{{ $t("menu.home") }}</span>
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/magazines" class="sidebar-item">
                <Magazine size="28" weight="fill" />
                <span>{{ $t("menu.magazines") }}</span>
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/articles" class="sidebar-item">
                <Article size="28" weight="fill" />
                <span>{{ $t("menu.articles") }}</span>
              </RouterLink>
            </li>
            <li v-if="authStore.user">
              <RouterLink to="/saved" class="sidebar-item">
                <Bookmark size="28" weight="fill" />
                <span>{{ $t("menu.saved") }}</span>
              </RouterLink>
            </li>
          </ul>
          <div class="h-px bg-border-primary my-4"></div>
          <div>
            <button
              class="sidebar-item-secondary mb-2"
              @click="showDownloadModal = true"
            >
              <MobilePhone size="28" /><span>{{
                $t("menu.download_app")
              }}</span>
            </button>
            <HelpButton />
          </div>
        </nav>
        <div class="mt-auto">
          <Socials />
        </div>
      </aside>
      <div class="mx-2 mt-4 flex-auto" @click="handleClose">
        <button class="btn-round-white" @click="handleClose">
          <XMark />
        </button>
      </div>
    </div>
  </transition>
  <Modal v-model="showDownloadModal">
    <AppDownloadModal @close="showDownloadModal = false" />
  </Modal>
</template>
