import { computed, Ref } from "vue";
import { Edition } from "../types/edition";

export const MIN_PDF_SCALE = 1;
export const MAX_PDF_SCALE = 4;

export const useEditionProgress = (edition: Ref<Edition | undefined>) => {
  const hasProgress = computed(() => {
    if (!edition.value) return false;
    return !!edition.value.end_user_edition_data?.last_visited_page_at;
  });

  const progress = computed(() => {
    if (!edition.value) return 0;
    const lastPage =
      edition.value.end_user_edition_data?.last_visited_page_number;
    if (!lastPage) return 0;
    return Math.round((lastPage / edition.value.page_amount) * 100);
  });

  return {
    hasProgress,
    progress,
  };
};
