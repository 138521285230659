<script setup lang="ts">
import LoadingIndicator from "./general/LoadingIndicator.vue";
import appConfig from "../lib/appConfig";
import { useAppSettingsStore } from "../stores/appSettingsStore";
import { useAuthStore } from "../stores/authStore";

const appSettingsStore = useAppSettingsStore();
const authStore = useAuthStore();
</script>

<template>
  <transition>
    <div
      v-if="appSettingsStore.isLoading || authStore.isLoading"
      class="fixed top-0 left-0 right-0 bottom-0 bg-background-primary z-50 flex-center"
    >
      <div class="flex flex-col items-center">
        <img
          class="w-60 mb-6"
          :src="appConfig.images.logo_full_color"
          :alt="appConfig.strings.app_name"
        />
        <LoadingIndicator class="text-icon-brand" />
      </div>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </transition>
</template>
