import { EndUser } from "../types/user";
import appConfig from "./appConfig";

export const startLogin = async () => {
  const { host, authorizePath, clientId } = appConfig.auth;
  const url = new URL(`https://${host}`);
  url.pathname = authorizePath;
  url.searchParams.append("response_type", "code");
  url.searchParams.append("client_id", clientId);
  url.searchParams.append("scope", "email");

  window.open(url.toString(), "_self");
};

export const startLogout = async () => {
  const { host, logoutPath, clientId, callbackUrlLogout } = appConfig.auth;
  const url = new URL(`https://${host}`);
  url.pathname = logoutPath;
  url.searchParams.append("response_type", "code");
  url.searchParams.append("client_id", clientId);
  url.searchParams.append("scope", clientId);
  url.searchParams.append("redirect", callbackUrlLogout);

  window.open(url.toString(), "_self");
};

export const getUserValue = <T>(user: EndUser, property: string): T | null => {
  const propertyValue = user.end_user_property_values?.data.find(
    (prop) => prop.end_user_property?.title === property
  );
  return propertyValue ? (propertyValue.value as T) : null;
};
