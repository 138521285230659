<script setup lang="ts">
import { useQueries } from "@tanstack/vue-query";
import EdtionsCarousel from "../editions/EdtionsCarousel.vue";
import ArticleCard from "../ArticleCard.vue";
import {
  getTitleEditionsSearch,
  getTitleArticlesSearch,
  EditionInclude,
  ArticleInclude,
} from "../../repository";
import { toRef, computed } from "vue";
import appConfig from "../../lib/appConfig";
import LoadingIndicator from "../general/LoadingIndicator.vue";
import { EmptyState, ErrorState } from "../states";

const props = defineProps<{
  searchQuery: string;
}>();

defineEmits(["openTab"]);

const results = useQueries({
  queries: [
    {
      queryKey: ["edition-search", toRef(props, "searchQuery")],
      queryFn: () =>
        getTitleEditionsSearch({
          query: props.searchQuery,
          titleId: appConfig.titleId,
          cursor: "null",
          include: [
            EditionInclude.EndUserEditionData,
            EditionInclude.EndUserSubscription,
          ],
        }),
    },
    {
      queryKey: ["article-search", toRef(props, "searchQuery")],
      queryFn: () =>
        getTitleArticlesSearch({
          query: props.searchQuery,
          titleId: appConfig.titleId,
          cursor: "null",
          include: [ArticleInclude.Edition],
        }),
    },
  ],
});

const editions = computed(() => results.value[0]);
const editionsIsEmpty = computed(() => {
  const editionData = editions.value?.data?.data.data;
  return !editionData || editionData.length < 1;
});
const articles = computed(() => results.value[1]);
const articlesIsEmpty = computed(() => {
  const articleData = articles.value?.data?.data.data;
  return !articleData || articleData.length < 1;
});
</script>

<template>
  <div class="py-4">
    <div>
      <h2 class="font-bold text-lg px-4">{{ $t("search.label.magazines") }}</h2>
      <div class="mt-2">
        <div
          v-if="editions.isLoading || editions.isError || editionsIsEmpty"
          class="flex-center min-h-40"
        >
          <LoadingIndicator v-if="editions.isLoading" class="text-icon-brand" />
          <ErrorState
            v-else-if="editions.isError"
            :contentName="$t('common.editions')"
            @refetch="results[0].refetch"
          />
          <EmptyState
            v-else-if="editionsIsEmpty"
            :title="$t('search.no_results.title')"
            :description="$t('search.no_results.description')"
          />
        </div>
        <template v-else-if="editions?.data">
          <EdtionsCarousel :editions="editions.data.data.data" class="px-4" />
          <div class="px-4 py-2" v-if="editions.data.data.meta.next_cursor">
            <button
              class="text-text-brand underline"
              @click="$emit('openTab', 'magazines')"
            >
              {{ $t("search.view_more") }}
            </button>
          </div>
        </template>
      </div>
    </div>
    <div class="mt-4 px-4">
      <h2 class="font-bold text-lg">{{ $t("search.label.articles") }}</h2>
      <div class="mt-2">
        <div
          v-if="articles.isLoading || articles.isError || articlesIsEmpty"
          class="flex-center min-h-40"
        >
          <LoadingIndicator v-if="articles.isLoading" class="text-icon-brand" />
          <ErrorState
            v-else-if="articles.isError"
            :contentName="$t('common.articles')"
            @refetch="results[1].refetch"
          />
          <EmptyState
            v-else-if="editionsIsEmpty"
            :title="$t('search.no_results.title')"
            :description="$t('search.no_results.description')"
          />
        </div>
        <template v-if="articles.data">
          <div class="py-2 grid grid-cols-1 sm:grid-cols-2 gap-2">
            <ArticleCard
              class="h-20"
              v-for="article in articles.data.data.data"
              :key="article.id"
              :article="article"
              vertical
            />
          </div>
          <div class="py-2" v-if="articles.data.data.meta.next_cursor">
            <button
              class="text-text-brand underline"
              @click="$emit('openTab', 'articles')"
            >
              {{ $t("search.view_more") }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
