<script setup lang="ts">
import { WhatsApp, Facebook, XTwitter, Mail } from "./icons";
import { notify } from "@kyvg/vue3-notification";
import XMark from "./icons/XMark.vue";
import { useQuery } from "@tanstack/vue-query";
import { postArticleShareLink, postEditionShareLink } from "../repository";
import { computed, toRef } from "vue";
import LoadingIndicator from "./general/LoadingIndicator.vue";
import { ErrorState } from "./states";
import { useI18n } from "vue-i18n";
import { AnalyticsEvent, useAnalytics } from "../lib/analytics";
import { Edition } from "../types/edition";
import { Article } from "../types/article";

const emit = defineEmits(["close"]);

const props = defineProps<{
  type: "edition" | "article";
  edition?: Edition;
  article?: Article;
}>();

const { t } = useI18n();
const { logEvent } = useAnalytics();

const id = computed(() => {
  if (props.type === "article") {
    return props.article?.id;
  }
  return props.edition?.id;
});

const { isLoading, isError, data } = useQuery({
  queryKey: ["link", toRef(props, "type"), id],
  queryFn: () =>
    props.type === "article"
      ? postArticleShareLink(id.value!)
      : postEditionShareLink(id.value!),
});

const link = computed(() => data.value?.data.data.url);
const encodedLink = computed(() => encodeURIComponent(link.value || ""));

const handleClose = () => {
  emit("close");
};

const copyLink = () => {
  if (!link.value) return;
  navigator.clipboard.writeText(link.value);
  onShare("copy");
  notify({
    type: "success",
    title: t("share_modal.success_message.title"),
    text: t("share_modal.success_message.text"),
  });
};

const emailSubject = computed(() =>
  props.type === "article"
    ? t("share_modal.email_subject.article")
    : t("share_modal.email_subject.magazine")
);

const onShare = (method: string) => {
  logEvent(AnalyticsEvent.Share, {
    contentId: id.value!,
    contentType: props.type,
    contentName:
      props.type === "article" ? props.article?.name : props.edition?.name,
    method,
  });
};
</script>

<template>
  <div class="w-full">
    <div class="flex items-center justify-between p-4 md:p-6">
      <h2 class="font-black text-xl">{{ $t("share_modal.title") }}</h2>
      <button class="btn-round-secondary" @click="handleClose">
        <XMark />
      </button>
    </div>
    <div v-if="isLoading || isError" class="p-4 md:p-6">
      <div v-if="isLoading" class="h-24 flex-center">
        <LoadingIndicator class="text-icon-brand" />
      </div>
      <ErrorState v-if="isError" contentName="link" />
    </div>
    <div v-if="link">
      <div class="overflow-auto w-full py-2 px-4">
        <div class="flex gap-4">
          <a
            :href="`//api.whatsapp.com/send?text=${encodedLink}`"
            @click="onShare('whatsapp')"
            target="_blank"
            class="flex flex-col items-center"
          >
            <div
              class="w-16 h-16 bg-[#00CE63] text-text-white rounded-full flex-center"
            >
              <WhatsApp size="32" />
            </div>
            <p class="text-sm mt-1">WhatsApp</p>
          </a>
          <a
            :href="`//www.facebook.com/sharer.php?u=${encodedLink}`"
            @click="onShare('facebook')"
            target="_blank"
            class="flex flex-col items-center"
          >
            <div
              class="w-16 h-16 bg-[#0766FF] text-text-white rounded-full flex-center"
            >
              <Facebook size="32" />
            </div>
            <p class="text-sm mt-1">Facebook</p>
          </a>
          <a
            :href="`//twitter.com/intent/tweet/?text=${encodedLink}`"
            @click="onShare('twitter')"
            target="_blank"
            class="flex flex-col items-center"
          >
            <div
              class="w-16 h-16 bg-[#000000] text-text-white rounded-full flex-center"
            >
              <XTwitter size="32" />
            </div>
            <p class="text-sm mt-1">X</p>
          </a>
          <a
            :href="`mailto:?subject=${emailSubject}&body=${encodedLink}`"
            @click="onShare('email')"
            class="flex flex-col items-center pr-4"
          >
            <div
              class="w-16 h-16 bg-background-secondary text-text-primary rounded-full flex-center"
            >
              <Mail size="32" />
            </div>
            <p class="text-sm mt-1">E-mail</p>
          </a>
        </div>
      </div>
      <div class="p-4 md:p-6">
        <div class="flex items-center pt-4 border-t border-border-primary">
          <div
            class="bg-background-secondary overflow-hidden py-2 px-4 rounded-xl border border-border-primary mr-2"
          >
            <p class="text-nowrap">
              {{ link }}
            </p>
          </div>
          <button @click="copyLink" class="btn-primary">
            {{ $t("share_modal.copy_button") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
