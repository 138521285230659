<script setup lang="ts">
import { Collapse } from "vue-collapsed";
import { ref } from "vue";
import { ChevronDown } from "../icons";

const props = defineProps<{
  faq: {
    question: string;
    answer: string;
  };
  initialOpen?: boolean;
}>();

const open = ref(props.initialOpen ?? false);
</script>

<template>
  <div class="bg-background-secondary rounded-xl">
    <button
      class="flex justify-between p-4 rounded-xl w-full"
      type="button"
      @click="open = !open"
    >
      <p class="font-bold text-left">{{ faq.question }}</p>
      <ChevronDown
        class="text-icon-brand transition-transform shrink-0"
        :class="{ 'transform rotate-180': open }"
      />
    </button>
    <Collapse :when="open">
      <div class="px-4 pb-4 faq-body" v-html="faq.answer"></div>
    </Collapse>
  </div>
</template>
