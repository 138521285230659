<script setup lang="ts">
import LoadingIndicator from "./LoadingIndicator.vue";

const props = withDefaults(
  defineProps<{
    loading: boolean;
    theme?: "primary" | "secondary" | "white";
  }>(),
  {
    theme: "primary",
  }
);
</script>

<template>
  <button :class="`btn-${theme}`">
    <LoadingIndicator v-if="props.loading" size="sm" />
    <template v-else>
      <slot />
    </template>
  </button>
</template>
