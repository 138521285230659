<script setup lang="ts">
import { ChevronRight, User } from "../icons";
import { ref } from "vue";
import { useAuthStore } from "../../stores/authStore";
import { getUserValue } from "../../lib/auth";

const authStore = useAuthStore();

const open = ref(false);
</script>

<template>
  <div
    v-if="authStore.user"
    class="relative z-20"
    @mouseover="open = true"
    @mouseleave="open = false"
  >
    <button
      class="bg-background-brand text-icon-white w-12 h-12 rounded-xl flex-center relative z-10"
      @click="open = !open"
      @touchstart.prevent="open = !open"
    >
      <User size="38" />
    </button>
    <transition>
      <div
        v-if="open"
        class="bg-background-primary border border-border-primary shadow-card p-4 rounded-xl absolute top-0 right-0 -mt-4 -mr-4 min-w-60 max-w-80"
      >
        <div
          v-if="authStore.user"
          class="flex flex-col justify-center pr-16 h-12 mb-4 whitespace-nowrap"
        >
          <p class="overflow-hidden text-ellipsis">
            {{ getUserValue(authStore.user, "firstname") }}
            {{ getUserValue(authStore.user, "lastname") }}
          </p>
          <p class="text-sm opacity-60 overflow-hidden text-ellipsis">
            {{ authStore.user.remote_human_id }}
          </p>
        </div>
        <div class="py-4 border-t border-b border-border-primary mb-4">
          <ul @click="open = false">
            <li>
              <RouterLink to="/account" class="account-menu-item">
                <span>{{ $t("account_menu.personal_info") }}</span>
                <ChevronRight size="16" />
              </RouterLink>
            </li>
          </ul>
        </div>
        <div>
          <button @click="authStore.showLogoutModal = true">
            {{ $t("common.logout") }}
          </button>
        </div>
      </div>
    </transition>
  </div>
  <div v-else>
    <button class="btn-primary" @click="authStore.showLoginModal = true">
      {{ $t("common.sign_in") }}
    </button>
  </div>
</template>
