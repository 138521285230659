<script setup lang="ts">
import AccountMenu from "./AccountMenu.vue";
import MobileSidebar from "./MobileSidebar.vue";
import MenuButton from "./MenuButton.vue";
import appConfig from "../../lib/appConfig";
import { useContentStore } from "../../stores/contentStore";
import { Search } from "../icons";
import { ref } from "vue";

const openSidebar = ref(false);

const contentStore = useContentStore();
</script>

<template>
  <div
    class="bg-background-primary border-b border-border-primary flex items-center p-4"
  >
    <MenuButton @click="openSidebar = true" />
    <RouterLink to="/" class="ml-2">
      <img
        class="w-24"
        :src="appConfig.images.logo_color"
        :alt="appConfig.strings.app_name"
      />
    </RouterLink>
    <div class="ml-auto flex">
      <button @click="contentStore.showSearchModal = true" class="p-1">
        <Search />
      </button>
      <AccountMenu class="ml-2" />
    </div>
  </div>
  <MobileSidebar v-model="openSidebar" />
</template>
