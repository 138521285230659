<script setup lang="ts">
defineProps<{
  value: number;
}>();

const circumference = Math.PI * 7 * 2;
</script>

<template>
  <svg
    class="text-icon-brand"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="20"
    height="20"
  >
    <circle
      class="stroke-icon-primary/10"
      cx="10"
      cy="10"
      r="7"
      fill="none"
      stroke="currentColor"
      stroke-width="3"
    />
    <circle
      cx="10"
      cy="10"
      r="7"
      stroke-linecap="round"
      fill="none"
      stroke="currentColor"
      stroke-width="3"
      :stroke-dasharray="circumference"
      :stroke-dashoffset="circumference * (1 - value / 100)"
      transform="rotate(-90 10 10)"
    />
  </svg>
</template>
