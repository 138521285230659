import nl from "../locales/nl.json";
import { createI18n } from "vue-i18n";

const instance = createI18n({
  legacy: false,
  locale: "nl",
  messages: {
    nl,
  },
});

export const i18n = instance.global;

export default instance;
