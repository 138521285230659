<script setup lang="ts">
import { ref } from "vue";
import { startLogin } from "../lib/auth";
import appConfig from "../lib/appConfig";
import LoadingButton from "./general/LoadingButton.vue";
const loading = ref(false);

const emit = defineEmits(["close"]);

const onLogin = () => {
  loading.value = true;
  startLogin();
};
</script>

<template>
  <div class="rounded-xl overflow-hidden w-full">
    <svg style="position: absolute; width: 0; height: 0">
      <clipPath id="header-clip-path" clipPathUnits="objectBoundingBox">
        <path
          d="M1,0 H0 V1 C0.151,0.944,0.321,0.913,0.5,0.913 C0.679,0.913,0.849,0.944,1,1 V0"
        ></path>
      </clipPath>
    </svg>
    <div
      class="h-60 bg-cover bg-center"
      :style="`background-image: url(${appConfig.images.covers}); clip-path: url(#header-clip-path);`"
    />
    <div class="p-4">
      <img
        class="w-56 mx-auto mb-16"
        :src="appConfig.images.logo_full_color"
        :alt="appConfig.strings.app_name"
      />
      <div class="flex flex-col items-center">
        <LoadingButton class="w-full mb-2" :loading="loading" @click="onLogin">
          {{ $t("login_modal.login") }}
        </LoadingButton>
        <button class="btn-secondary w-full" @click="$emit('close')">
          {{ $t("common.close") }}
        </button>
        <p class="py-4 text-center">
          {{ $t("login_modal.no_subscription") }}
          <a
            :href="appConfig.links.subscription_url"
            class="underline text-text-brand"
          >
            {{ $t("login_modal.subscribe") }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>
