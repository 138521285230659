<script setup lang="ts">
import XMark from "../icons/XMark.vue";
import TextField from "../form/TextField.vue";
import TextArea from "../form/TextArea.vue";
import Checkbox from "../form/Checkbox.vue";
import LoadingButton from "../general/LoadingButton.vue";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { nl } from "yup-locales";
import { ref } from "vue";
import { notify } from "@kyvg/vue3-notification";
import { ContactBody, sendContactForm } from "../../repository";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "../../stores/authStore";
import { getUserValue } from "../../lib/auth";
import { getLocalInstallationId, getSessionId } from "../../lib/logger";
import FaqQuestion from "./FaqQuestion.vue";
import appConfig from "../../lib/appConfig";

yup.setLocale(nl);

const emit = defineEmits(["close"]);

const authStore = useAuthStore();
const { t } = useI18n();
const loading = ref(false);

const schema = yup.object({
  name: yup.string().required().label(t("contact_form.name_label")),
  email: yup.string().email().required().label(t("contact_form.email_label")),
  message: yup.string().required().label(t("contact_form.message_label")),
});

const { handleSubmit, setFieldValue } = useForm({
  validationSchema: schema,
});

const setUserFields = () => {
  if (authStore.user) {
    const firstName = getUserValue(authStore.user, "firstname");
    const lastName = getUserValue(authStore.user, "lastname");
    if (firstName && lastName) {
      setFieldValue("name", `${firstName} ${lastName}`);
    }
    setFieldValue("email", authStore.user.remote_human_id);
  }
};
setUserFields();

const submit = handleSubmit(async (values) => {
  if (loading.value) return;
  try {
    loading.value = true;
    const data: ContactBody = {
      name: values.name,
      email: values.email,
      message: values.message,
    };
    if (values.share_diagnostics) {
      data.appSessionUuid = getSessionId();
      data.appInstallationUuid = getLocalInstallationId();
    }

    await sendContactForm(data);
    notify({
      type: "success",
      title: t("contact_form.success_message.title"),
      text: t("contact_form.success_message.text"),
    });
    emit("close");
  } catch (error) {
    notify({
      type: "error",
      title: t("common.error.title"),
      text: t("common.error.description"),
    });
  } finally {
    loading.value = false;
  }
});
</script>

<template>
  <div class="w-full">
    <div class="flex items-center justify-between p-4 md:p-6">
      <h2 class="font-black text-xl">{{ $t("help_menu.contact_form") }}</h2>
      <button @click="$emit('close')" class="btn-round-secondary">
        <XMark />
      </button>
    </div>
    <form @submit="submit" class="px-4 md:px-6 pb-4 md:pb-6 grid gap-4">
      <template v-if="appConfig.contactFaqs?.length">
        <div class="grid gap-2">
          <FaqQuestion
            v-for="(faq, i) in appConfig.contactFaqs"
            :key="i"
            :faq="faq"
            initialOpen
          />
        </div>
        <div class="w-full h-px bg-border-primary" />
      </template>
      <TextField
        name="name"
        :label="$t('contact_form.name_label')"
        :placeholder="$t('contact_form.name_placeholder')"
      />
      <TextField
        name="email"
        :label="$t('contact_form.email_label')"
        :placeholder="$t('contact_form.email_placeholder')"
      />
      <TextArea name="message" :rows="3" />
      <Checkbox name="share_diagnostics">
        {{ $t("contact_form.share_diagnostics") }}
      </Checkbox>
      <LoadingButton class="w-full" type="submit" :loading="loading">
        {{ $t("contact_form.button") }}
      </LoadingButton>
    </form>
  </div>
</template>
