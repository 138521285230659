<script setup lang="ts">
import LoadingButton from "./general/LoadingButton.vue";
import { startLogout } from "../lib/auth";
import { ref } from "vue";

const loading = ref(false);
const emit = defineEmits(["close"]);

const handleLogout = () => {
  loading.value = true;
  startLogout();
};
</script>

<template>
  <div class="p-4 w-full">
    <h2 class="text-xl font-bold mb-6">{{ $t("logout_modal.title") }}</h2>
    <div class="grid grid-cols-2 gap-3">
      <button @click="$emit('close')" class="btn-secondary">
        {{ $t("common.cancel") }}
      </button>
      <LoadingButton @click="handleLogout" :loading="loading">
        {{ $t("logout_modal.logout_button") }}
      </LoadingButton>
    </div>
  </div>
</template>
