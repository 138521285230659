<script setup lang="ts">
import { useInfiniteQuery } from "@tanstack/vue-query";
import ArticleCard from "../ArticleCard.vue";
import { getTitleArticlesSearch, ArticleInclude } from "../../repository";
import { computed, ref, toRef } from "vue";
import appConfig from "../../lib/appConfig";
import { useInfinityScroll } from "../../lib/infinityScroll";
import LoadingIndicator from "../general/LoadingIndicator.vue";
import { ErrorState, EmptyState } from "../states";

const props = defineProps<{
  searchQuery: string;
}>();

const scrollComponent = ref<HTMLElement>();

const {
  data,
  isLoading,
  isError,
  refetch,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
} = useInfiniteQuery({
  queryKey: ["article-search-tab", toRef(props, "searchQuery")],
  queryFn: ({ pageParam }) =>
    getTitleArticlesSearch({
      query: props.searchQuery,
      titleId: appConfig.titleId,
      cursor: pageParam,
      include: [ArticleInclude.Edition],
    }),
  initialPageParam: "null",
  getNextPageParam: (lastPage) => {
    return lastPage.data.meta.next_cursor;
  },
});

const articles = computed(() => {
  return data.value?.pages.flatMap((page) => page.data.data) ?? [];
});

const isEmpty = computed(() => articles.value?.length === 0);

const fetchPage = () => {
  if (isFetchingNextPage.value) return;
  fetchNextPage();
};

useInfinityScroll({
  scrollComponent,
  fetchNextPage: fetchPage,
  scrollContainer: "#search-modal-container",
});
</script>

<template>
  <div class="p-4" ref="scrollComponent">
    <div v-if="isLoading || isError || isEmpty" class="flex-center min-h-40">
      <LoadingIndicator v-if="isLoading" class="text-icon-brand" />
      <ErrorState
        v-else-if="isError"
        :contentName="$t('common.articles')"
        @refetch="refetch"
      />
      <EmptyState
        v-else-if="isEmpty"
        :title="$t('search.no_results.title')"
        :description="$t('search.no_results.description')"
      />
    </div>
    <template v-else-if="articles">
      <div class="py-2 grid grid-cols-1 sm:grid-cols-2 gap-2">
        <ArticleCard
          class="h-20"
          v-for="article in articles"
          :key="article.id"
          :article="article"
          vertical
        />
      </div>
      <div v-if="isFetchingNextPage" class="w-full flex-center py-4">
        <LoadingIndicator class="text-icon-brand" />
      </div>
      <div v-else-if="hasNextPage" class="w-full flex-center py-4">
        <button class="btn-primary" @click="fetchPage">
          {{ $t("common.load_more") }}
        </button>
      </div>
    </template>
  </div>
</template>
