<script setup lang="ts">
import { AxiosError } from "axios";
import { computed, getCurrentInstance } from "vue";
defineEmits(["refetch"]);

const props = defineProps<{
  contentName?: string;
  error?: Error | null;
}>();

const hasRefetch = computed(() => {
  return !!getCurrentInstance()?.vnode.props?.["onRefetch"];
});

const isNotFoundError = computed(() => {
  if (props.error instanceof AxiosError) {
    return props.error.response?.status === 404;
  }
  return false;
});

const capitalizeString = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
</script>

<template>
  <div class="text-center" v-if="isNotFoundError">
    <p class="font-bold">{{ $t("common.not_found.title") }}</p>
    <p class="text-text-secondary">
      {{
        props.contentName
          ? $t("common.not_found.named_description", {
              name: capitalizeString(props.contentName),
            })
          : $t("common.not_found.description")
      }}
    </p>
    <RouterLink class="btn-white inline-block btn--border mt-2" to="/">
      {{ $t("common.not_found.button") }}
    </RouterLink>
  </div>
  <div v-else class="text-center">
    <p class="font-bold">{{ $t("common.error.title") }}</p>
    <p class="text-text-secondary">
      {{
        props.contentName
          ? $t("common.error.named_description", { name: props.contentName })
          : $t("common.error.description")
      }}
    </p>
    <button
      v-if="hasRefetch"
      class="btn-white inline-block btn--border mt-2"
      @click="$emit('refetch')"
    >
      {{ $t("common.error.retry") }}
    </button>
  </div>
</template>
